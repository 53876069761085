<template>
	<b-modal ref="modalIntraLocation" @hidden="resetModal">
		<template slot="modal-header" slot-scope="{ close }">
			<h2 class="mx-auto my-auto">{{ $t('intra_location.ajouter') }}</h2>
			<i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'times-circle']" /></i>
		</template>
		<template slot="default">
			<div class="row">
				<div class="col-12">
					<div class="form-group">
						<label>{{ $t("intra_location.moving_date") }}<sup>*</sup></label>
						<e-datepicker id="date_intra_location" v-model="mouvement_date"></e-datepicker>
					</div>

					<AskIntraLocation :selected_location.sync="intra_location" :residence_id="residence_id" />
				</div>
			</div>
		</template>

		<template slot="modal-footer" class="justify-content-center">
			<b-button variant="primary" @click="saveForm()">
				{{ $t('global.enregistrer') }}
			</b-button>
		</template>
	</b-modal>
</template>


<script type="text/javascript">
	import IntraLocation from '@/mixins/IntraLocation'

	export default {
		name: 'ModalMoveIntraLocation',
		props: {
			residence_id: { type: Number, default: 0}
		},
		mixins: [
			IntraLocation
		],
		components: {
			AskIntraLocation: () => import('@/components/Mouvements/AskIntraLocation')
		},
		data () {
			return {
				intra_location: null,
				mouvement_date: new Date()
			}
		},
		methods: {
			saveForm() {
				this.$emit('complete', {
					'intra_location': this.intra_location,
					'mouvement_date': this.mouvement_date
				})
				this.hideModal()
			},

			openModal() {
				this.$refs.modalIntraLocation.show()
			},

			hideModal() {
				this.$refs.modalIntraLocation.hide()
			},

			resetModal() {
				this.intra_location = null
			}
		}
	}
</script>